import React from 'react';
import get from 'lodash/get';
import { Link, graphql } from 'gatsby';
import {
  Layout,
} from '../components';
import {
  Wrapper,
  Image,
  HtmlContent,
  Buttons,
} from '../components/common';
import { THEME_PRIMARY } from '../utils/brand';
import './thank-you.scss';

const ThankYou = (props) => {
  const {
    data: {
      page: {
        data: pageData,
      },
    },
    location,
  } = props;
  const seoData = {
    metaTitle: 'Thanks for your message! WAGEC',
    metaDescription: '',
    banner: '',
  };
  const {
    headline,
    subtitle,
    description,
    illustration,
    illustrator,
    buttons,
  } = pageData;
  const artistName = get(illustrator, 'document.data.name.text');
  return (
    <Layout location={location} seoData={seoData}>
      <section className="thank-you-page">
        <Wrapper>
          <div className="cols">
            <div className="col col-left">
              <div className="text-container">
                <span className="subtitle">{subtitle.text}</span>
                <h1 className="headline">{headline.text}</h1>
                <HtmlContent
                  className="description"
                  content={description.html}
                />
                {buttons && buttons.length > 0 && (
                  <Buttons
                    buttons={buttons}
                    theme={THEME_PRIMARY}
                  />
                )}
              </div>
            </div>
            <div className="col col-right">
              <div className="illustration">
                <div className="image-container">
                  <Image image={illustration} />
                  {artistName && (
                    <div className="artist-shout-out">
                      <div className="signature-container">
                        <span>Art by</span>
                        <Link to="/artists/">
                          {artistName}
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </section>
    </Layout>
  );
};

export default ThankYou;

export const pageQuery = graphql`
  query ThankYouQuery {
    page: prismicThankYou {
      uid
      data {
        subtitle {
          text
        }
        illustrator {
          id
          document {
            ... on PrismicArtist {
              data {
                name {
                  text
                }
              }
            }
          }
        }
        illustration {
          alt
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }
        headline {
          text
        }
        description {
          html
        }
        buttons {
          buttonLabel: button_label {
            text
          }
          buttonLink: button_link {
            slug
            url
            type
            linkType: link_type
          }
        }
      }
    }
  }
`;
